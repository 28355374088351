export interface AnimationObserverOptions {
  threshold?: number;
  rootMargin?: string;
  animationClass?: string;
}

export const setupScrollAnimations = (
  options: AnimationObserverOptions = {}
): void => {
  const defaultOptions: AnimationObserverOptions = {
    threshold: 0.1,
    rootMargin: "0px 0px -100px 0px",
    animationClass: "visible",
  };

  const { threshold, rootMargin, animationClass } = {
    ...defaultOptions,
    ...options,
  };

  if (typeof window === "undefined" || !window.IntersectionObserver) {
    // If browser doesn't support IntersectionObserver, make all elements visible
    const elements = document.querySelectorAll(".animate-on-scroll");
    elements.forEach((el) => {
      el.classList.add(animationClass || "visible");
    });
    return;
  }

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass || "visible");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold,
      rootMargin,
    }
  );

  const elements = document.querySelectorAll(".animate-on-scroll");
  elements.forEach((el) => {
    observer.observe(el);
  });
};

// Staggered animation for list items
export const setupStaggeredAnimations = (
  containerSelector: string,
  itemSelector: string,
  staggerDelay = 0.1
): void => {
  const containers = document.querySelectorAll(containerSelector);

  containers.forEach((container) => {
    const items = container.querySelectorAll(itemSelector);
    items.forEach((item, index) => {
      (item as HTMLElement).style.transitionDelay = `${index * staggerDelay}s`;
    });
  });
};